import React, { useState, useEffect, useRef } from 'react';
import { addLocale } from 'primereact/api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import classNames from 'classnames';

import { format } from "date-fns";
import { environment } from "./util/baseUrl";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar',
});

const Perfil = () => {
    let empty = {
        id: null,
        username: '',
        email: '',
        password: '',
        nombre: '',
        apellido: '',
        docIdentidad: '',
        fecNacimiento: '',
        nroTelefono: '',
        dependencia: '',
        institucion: '',
        cargo: '',
        genero: '',
        estado: true,
        roles: null,
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? null : format(date, 'dd/MM/yyyy'); // Cambia al formato yyyy-MM-dd para el backend
    };

    let pass = {
        id: null,
        oldPassword: '',
        newPassword: ''
    }

    const options = [
        'Masculino', 'Femenino', 'Otros'
    ];

    const inst = [
        'Poder Judicial', 'Ministerio Publico', 'Policia Nacional', 'Defensa Publica', 'Estudiante', 'Otros'
    ];

    const [errors, setErrors] = useState({
        confirmPassword: '',
        // otros campos de errores aquí
    });

    let navigate = useNavigate();
    const cookies = new Cookies();
    const [data, setData] = useState(empty);
    const [submitted, setSubmitted] = useState(false);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
    const [EntidadDialog, setEntidadDialog] = useState(false);
    const [product, setProduct] = useState(empty);
    const [passw, setPassw] = useState(pass);
    const [reniec, setReniec] = useState(null);
    const [gen, setGenero] = useState('');
    const [getInstitucion, setInstitucion] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const toast = useRef(null);
    const currentDate = new Date();


    const peticionGet = async () => {
        await axios.get(environment.baseUrl + "usuario/" + cookies.get('id'))
            .then(response => {
                setData(response.data);
                setProduct(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionRENIEC = async () => {
        /*await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQG91dGxvb2suY29tIn0.hfkwOQKGq46Czu7RvqI1iSwgN8UGK02UEF4S9ZqXaCQ")*/
        await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQHBqLmdvYi5wZSJ9.-nX87AiyjDvfW2SeGAhWFnx0MDCiB8meK06aAAlVfJQ")
            .then(response => {
                setReniec(response.data);
                console.log(response.data);
                const nombre = response.data.nombres;
                const apellido = response.data.apellidoPaterno + " " + response.data.apellidoMaterno; // Ajusta la ruta de acceso correcta a la propiedad "nombres" según la respuesta del API
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    nombre: nombre,
                    apellido: apellido,
                }));
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPut = async () => {
        console.log(pass);
        passw.id = cookies.get('id');
        console.log(pass);
        await axios.post(environment.baseUrl + 'usuario/changepassword', passw)
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const peticionPutMod = async () => {
        if (typeof product.fecNacimiento === 'string' && product.fecNacimiento.includes('/')) {
            console.log(product.fecNacimiento);
        } else {
            product.fecNacimiento = formatDate(product.fecNacimiento);
        }
        console.log(product);
        await axios.post(environment.baseUrl + "usuario/", product)
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
                setTimeout(() => {
                    navigate('/');
                }, 1500);
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const hideDialogNew = () => {
        setSubmitted(false);
        setEntidadNewDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEntidadDialog(false);
    }

    const openNew = () => {
        setPassw(pass);
        setSubmitted(false);
        setEntidadNewDialog(true);
    }

    const open = () => {
        setSubmitted(false);
        setEntidadDialog(true);
    }

    const saveProduct = () => {
        product.institucion = getInstitucion;
        product.genero = gen;
        console.log(pass);
        console.log(product);

        setSubmitted(true);
        if (passw.oldPassword.trim() && passw.newPassword.trim() && confirmPassword.trim()) {
            if (passw.newPassword === confirmPassword) {
                peticionPut();
                setEntidadNewDialog(false);
                setPassw(pass);
                setConfirmPassword('');
            } else {
                setErrors({ ...errors, confirmPassword: 'Las contraseñas no coinciden.' });
            }
            setEntidadNewDialog(false);
            setPassw(pass);
        }
        if (product.fecNacimiento && product.email && product.nroTelefono && product.cargo) {
            peticionPutMod();
            setEntidadDialog(false);
        }
    }

    const BuscarDNI = () => {
        setSubmitted(true);
        if (product.docIdentidad.trim()) {
            peticionRENIEC();
            //setProduct(empty);
        }
    }
    const onInputChangePass = (e, name) => {
        const val = (e.target && e.target.value) || '';
        if (name === 'newPassword' || name === 'confirmPassword') {
            if (name === 'newPassword') {
                setPassw({ ...passw, [name]: val });
            } else {
                setConfirmPassword(val);
            }
            if (passw.newPassword !== val) {
                setErrors({ ...errors, confirmPassword: 'Las contraseñas no coinciden.' });
            } else {
                setErrors({ ...errors, confirmPassword: '' });
            }
        } else {
            setPassw({ ...pass, [name]: val });
        }
        let _pass = { ...passw };
        _pass[`${name}`] = val;
        setPassw(_pass);
    }

    const onInputChange = (e, name) => {
        let val;

        if (name === 'fecNacimiento' || name === 'fecInicio') {
            val = e.value; // Asignar el valor de la fecha seleccionada
        } else {
            val = (e.target && e.target.value) || ''; // Asignar valor de otros campos
        }

        // Actualizar solo el campo específico en el producto
        setProduct(prevProduct => ({
            ...prevProduct, // Mantener todos los campos existentes sin cambios
            [name]: val  // Actualizar solo el campo correspondiente
        }));
    };

    const stringToDate = (dateString) => {
        if (!dateString) return null;
        // Si ya es un objeto Date, retornarlo directamente
        if (dateString instanceof Date) {
            return dateString;
        }
        // Si es un número (timestamp), convertirlo a Date
        if (typeof dateString === 'number') {
            return new Date(dateString);
        }
        // Asegurarse de que es un string antes de hacer split
        if (typeof dateString === 'string' && dateString.includes('/')) {
            const [day, month, year] = dateString.split('/').map(Number);
            return new Date(year, month - 1, day);
        }

        return null; // Retornar null si no es un formato válido
    };

    const productDialogFooterNew = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    useEffect(() => {
        peticionGet();
    }, []);

    return (
        <div className="grid">
            <Toast ref={toast} />
            <div className="col-12">
                <div className="card">
                    <div className="surface-section">
                        <div className="font-medium text-3xl text-900 mb-3">Información de Usuario</div>
                        <div className="text-500 mb-5">Datos personales.</div>
                        <ul className="list-none p-0 m-0">
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Nombres: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.nombre}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Apellidos: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.apellido}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">N° Documento: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.docIdentidad}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Fecha de Nacimiento: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.fecNacimiento}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Genero: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.genero}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Numero de Telefono: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.nroTelefono}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Correo Electronico: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.email}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Permisos: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"> {cookies.get('roles')}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Institucion: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.institucion}</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Cargo: </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{data.cargo}</div>
                            </li>
                        </ul>
                    </div>
                    <br />
                    <div className="my-2">
                        <Button label="Actualizar Datos" icon="pi pi-pencil" className="p-button-outlined p-button-success mr-2" onClick={open} />
                        <Button label="Cambiar Contraseña" icon="pi pi-lock-open" className="p-button-outlined p-button-success mr-2" onClick={openNew} />
                    </div>
                </div>
                <Dialog visible={EntidadNewDialog} style={{ width: '350px' }} header="Cambiar Contraseña" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText placeholder="Antiguo Password" type="password" id="oldPassword" name="oldPassword" value={passw.oldPassword} onChange={(e) => onInputChangePass(e, 'oldPassword')} required autoFocus className={classNames({ 'p-invalid': submitted && !passw.oldPassword })} />
                        </div>
                        {submitted && !passw.oldPassword && <small className="p-invalid">el old Password es requerido.</small>}
                    </div>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText placeholder="Nuevo Password" type="password" id="newPassword" name="newPassword" value={passw.newPassword} onChange={(e) => onInputChangePass(e, 'newPassword')} required className={classNames({ 'p-invalid': submitted && !passw.newPassword })} />
                        </div>
                        {submitted && !passw.newPassword && <small className="p-invalid">el new Password es requerido.</small>}
                    </div>
                    <div className="field">
                        <div className="p-inputgroup">
                            <InputText placeholder="Confirmar Password" type="password" id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={(e) => onInputChangePass(e, 'confirmPassword')} required className={classNames({ 'p-invalid': submitted && !confirmPassword })} />
                        </div>
                        {submitted && !passw.newPassword && <small className="p-invalid">La confirmacion de contraseña es requerida.</small>}
                        {submitted && errors.confirmPassword && <small className="p-invalid">{errors.confirmPassword}</small>}
                    </div>
                </Dialog>
                <Dialog visible={EntidadDialog} style={{ width: '500px' }} header="Datos de Usuario" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-12">
                            <label htmlFor="docIdentidad">Doc. de Identidad</label>
                            <div className="p-inputgroup">
                                <Button icon="pi pi-search" onClick={BuscarDNI} />
                                <InputText id="docIdentidad" name="docIdentidad" value={product.docIdentidad} onChange={(e) => onInputChange(e, 'docIdentidad')} required disabled className={classNames({ 'p-invalid': submitted && !product.docIdentidad })} />
                            </div>
                            {submitted && !product.docIdentidad && <small className="p-invalid">el documento de identidad es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-12">
                            <label htmlFor="nombre">Nombres</label>
                            <InputText id="nombre" name="nombre" value={product.nombre} onChange={(e) => onInputChange(e, 'nombre')} required disabled className={classNames({ 'p-invalid': submitted && !product.nombre })} />
                            {submitted && !product.nombre && <small className="p-invalid">el Nombre es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-12">
                            <label htmlFor="apellido">Apellidos</label>
                            <InputText id="apellido" name="apellido" value={product.apellido} onChange={(e) => onInputChange(e, 'apellido')} required disabled className={classNames({ 'p-invalid': submitted && !product.apellido })} />
                            {submitted && !product.apellido && <small className="p-invalid">el Apellido es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="fecNacimiento">Fecha de Nacimiento</label>
                            <Calendar id="fecNacimiento" name="fecNacimiento" placeholder="dd/mm/aaaa" value={product.fecNacimiento ? stringToDate(product.fecNacimiento) : product.fecNacimiento} dateFormat="dd/mm/yy" locale='es' onChange={(e) => onInputChange(e, 'fecNacimiento')} className={classNames({ 'p-invalid': submitted && !product.fecNacimiento })} />
                            {submitted && !product.fecNacimiento && <small className="p-invalid">Opcional.</small>}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="genero">Genero</label>
                            <Dropdown placeholder="Seleccionar Género" id="genero" name="genero" options={options} value={gen} onChange={(e) => setGenero(e.value)} />
                            {submitted && !product.genero && <small className="p-invalid">el genero es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="email">Correo</label>
                            <InputText id="email" name="email" value={product.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !product.email })} />
                            {submitted && !product.email && <small className="p-invalid">el Correo es requerido.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="nroTelefono">Telefono</label>
                            <InputText id="nroTelefono" name="nroTelefono" value={product.nroTelefono} onChange={(e) => onInputChange(e, 'nroTelefono')} required className={classNames({ 'p-invalid': submitted && !product.nroTelefono })} />
                            {submitted && !product.nroTelefono && <small className="p-invalid">el Telefono es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="institucion">Institucion</label>
                            <Dropdown placeholder="Seleccionar Institución" id="institucion" name="institucion" options={inst} value={getInstitucion} onChange={(e) => setInstitucion(e.value)} />
                            {submitted && !product.institucion && <small className="p-invalid">la institucion es requerida.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="cargo">Cargo</label>
                            <InputText id="cargo" name="cargo" value={product.cargo} onChange={(e) => onInputChange(e, 'cargo')} required className={classNames({ 'p-invalid': submitted && !product.cargo })} />
                            {submitted && !product.cargo && <small className="p-invalid">el cargo es requerido.</small>}
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default Perfil;