
export const UsuarioEntity = {
    id: null,
    username: '',
    email: '',
    password: '',
    nombre: '',
    apellido: '',
    docIdentidad: '',
    fecNacimiento: null,
    nroTelefono: '',
    dependencia: '',
    institucion: '',
    cargo: '',
    genero: '',
    estado: true,
    roles: {
        id: null,
        name: ''
    }
};
